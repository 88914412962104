import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";

export function formatDateTime(dateInput) {
  const dayjsDate = dayjs(dateInput);
  return dayjsDate.format("DD/MM/YYYY HH:mm");
}

export const formatDate = (dateInput) => {
  const dayjsDate = dayjs(dateInput);
  return dayjsDate.format("DD/MM/YYYY");
};
export const formatDateDayjs = (dateInput) => {
  try {
    return dateInput.format("DD/MM/YYYY");
  } catch (e) {
    console.error(e);
  }
};

export const checkResponse = (res) => {
  if (res?.data?.status === 1 && !res?.data?.errors) {
    return true;
  }
  return false;
};

export function scrollToIdWithSpaceOffset(elementId, offset) {
  try {
    const element = document.getElementById(elementId);
    if (!element) {
      console.error(`Element with ID "${elementId}" not found.`);
      return;
    }

    const rect = element.getBoundingClientRect();
    const targetTop = window.pageYOffset + rect.top - offset;

    window.scrollTo({
      top: targetTop,
      behavior: "smooth",
    });
  } catch (error) {}
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export const onFullScreen = () => {
  const elem = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    // Firefox
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    // Chrome, Safari and Opera
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    // IE/Edge
    elem.msRequestFullscreen();
  }
};

export const formatTime = (time) => {
  if (time && !isNaN(time)) {
    const minutes = Math.floor(time / 60);
    const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(time % 60);
    const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${formatMinutes}:${formatSeconds}`;
  }
  return "00:00";
};

export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      enqueueSnackbar("Đã sao chép", {
        variant: "success",
      });
    })
    .catch((err) => {
      enqueueSnackbar("Lỗi sao chép", {
        variant: "error",
      });
    });
};

export const isScrolledIntoView = (el) => {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;
  // Only completely visible elements return true:
  // const isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
  // Partially visible elements return true:
  return elemTop < window.innerHeight && elemBottom >= 0;
};

export const showNotiError = (response) => {
  const msg = response?.data?.errors?.[0]?.message;
  console.log(msg);
  enqueueSnackbar(msg || "Lỗi", {
    variant: "error",
  });
  return msg;
};

export const showNotiSuccess = (msg) => {
  enqueueSnackbar(msg, {
    variant: "success",
  });
};

export const filterArray = (array, field) => {
  if (!array || !Array.isArray(array)) return [];
  return array?.reduce((accumulator, current) => {
    const x = accumulator.find((item) => item[field] === current[field]);
    if (!x) {
      return accumulator.concat([current]);
    } else {
      return accumulator;
    }
  }, []);
};

export const handleCopyClipboard = (text) => {
  return navigator.clipboard.writeText(text);
};

export const handleReadClipboard = () => {
  return navigator.clipboard.readText();
};

export const getSlug = (text) => {
  if (!text || typeof text !== "string") return "";
  return text
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[đĐ]/g, "d")
    .replace(/([^0-9a-z-\s])/g, "")
    .replace(/(\s+)/g, "-")
    .replace(/-+/g, "-")
    .replace(/^-+|-+$/g, "");
};

export const isLastElement = (array, index) => {
  return index === array.length - 1;
};
export const isFirstElement = (index) => {
  return index === 0;
};

export const parseParamsBlock = (params) => {
  try {
    const parsePrs = JSON.parse(params);
    let obj = {};
    for (const key in parsePrs) {
      if (Object.prototype.hasOwnProperty.call(parsePrs, key)) {
        const element = parsePrs[key];
        if (!element.startsWith("$$")) {
          obj[key] = element;
        }
      }
    }
    return obj;
  } catch (error) {}
};
