import { yupResolver } from "@hookform/resolvers/yup";
import iconApple from "assets/icons/apple.svg";
import iconFacebook from "assets/icons/facebook.svg";
import iconGoogle from "assets/icons/google.svg";
import LoadingButton from "components/Button/LoadingButton";
import PasswordInputField from "components/Form/PasswordInputField";
import TextInputField from "components/Form/TextField";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithCredential,
  signInWithPopup,
} from "firebase/auth";
import { useServices } from "hooks/useServices";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getUserDetail, loginByPassword, loginWithSocial } from "services";
import { checkResponse } from "utils";
import { INFO_LOGIN, saveItemStorage } from "utils/storage";
import * as yup from "yup";
import {
  facebookProvider,
  googleProvider,
  myAuth,
} from "../../plugin/firebase";
import { setLoggedIn, setUserInfo } from "../../redux/slice/authSlice";
import ModalBase from "./ModalBase";

const auth = getAuth();

const schema = yup
  .object({
    username: yup.string().required("Không được để trống"),
    password: yup.string().required("Không được để trống"),
  })
  .required();

export default function LoginModal({
  open,
  handleClose,
  setIsRegisterOpen,
  setIsForgetOpen,
}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const services = useServices();
  const { control, handleSubmit } = useForm({
    mode: "all",
    defaultValues: {
      username: process.env.REACT_APP_IS_DEV ? "dinhquyf8@gmail.com" : "",
      password: process.env.REACT_APP_IS_DEV ? "1234567a" : "",
    },
    resolver: yupResolver(schema),
  });

  const onLoginSuccess = (responseLogin) => {
    saveItemStorage(INFO_LOGIN, {
      apiSecret: responseLogin.apiSecret,
      refreshKey: responseLogin.refreshKey,
      userId: responseLogin.userId,
    });
    services(() => getUserDetail({ id: responseLogin.userId }))
      .then((resDetail) => {
        if (checkResponse(resDetail)) {
          dispatch(setLoggedIn(true));
          dispatch(setUserInfo(resDetail.data.data));
        }
      })
      .finally(() => {
        handleClose();
      });
  };
  const onSubmit = (data) => {
    setLoading(true);
    services(() => loginByPassword(data))
      .then((res) => {
        if (checkResponse(res)) {
          const responseLogin = res.data.data;
          onLoginSuccess(responseLogin);
        } else {
          dispatch(setLoggedIn(false));
          const err = res?.data?.errors?.[0]?.message;
          enqueueSnackbar(err ?? "Tài khoản hoặc mật khẩu không chính xác", {
            variant: "error",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    const authChange = onAuthStateChanged(myAuth, (user) => {
      if (user) {
        console.log(user);
        const uid = user.uid;
      } else {
      }
    });
    return () => {
      authChange();
    };
  }, []);
  const loginGoogle = () => {
    signInWithPopup(myAuth, googleProvider)
      .then(async (result) => {
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.
        // const user = result.user;
        // console.log({token, user, credential});

        const uid = result.user.uid;
        const accessToken = await result.user.accessToken;

        loginWithSocial({
          signal: uid,
          token: accessToken,
          type: 2, //1:fb,2:gg,3:apple
        }).then((res) => {
          if (checkResponse(res)) {
            console.log(res);
            const responseLogin = res.data.data;
            onLoginSuccess(responseLogin);
          } else {
            console.log(res.errors);
          }
        });
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // // The email of the user's account used.
        // const email = error.customData.email;
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // // ...
      });
  };

  const loginFacebook = () => {
    signInWithPopup(auth, facebookProvider)
      .then((result) => {
        console.log(result);
        const user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessTokenCredential = credential.accessToken;
        const accessToken = result.user.accessToken;
        const uid = result.user.uid;
        if (accessTokenCredential) {
          loginWithSocial({
            signal: uid,
            token: accessToken,
            type: 1, //1:fb,2:gg,3:apple
          }).then((res) => {
            if (checkResponse(res)) {
              const responseLogin = res.data.data;
              onLoginSuccess(responseLogin);
            } else {
              console.log(res.errors);
            }
          });
        }
      })
      .catch((error) => {
        console.log("xxx", error);
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
      });
  };

  const loginApple = async () => {
    const provider = new OAuthProvider("apple.com");
    try {
      const result = await signInWithPopup(auth, provider);
      // Đăng nhập thành công
      const user = result.user;
      console.log("User Info: ", user);
      alert("Đăng nhập Apple thành công!");
    } catch (error) {
      console.error("Error during Apple sign-in: ", error);
      console.log(error.message);
    }
  };
  return (
    <ModalBase
      title={"Đăng nhập"}
      open={open}
      handleClose={handleClose}
      className="w-full sm:w-[485px]"
    >
      <div className="self-stretch px-4 py-8 flex-col justify-start items-start gap-8 flex">
        <div className="self-stretch flex-col justify-start items-start gap-8 flex">
          <div className="self-stretch flex-col justify-start items-end gap-4 flex">
            <TextInputField
              placeholder={"Emai hoặc số điện thoại"}
              name="username"
              control={control}
            />
            <PasswordInputField
              placeholder={"Mật khẩu"}
              name="password"
              control={control}
            />
            <div
              onClick={() => {
                handleClose();
                setIsForgetOpen(true);
              }}
              className="text-right text-[#3471e9] text-sm font-medium leading-snug cursor-pointer"
            >
              Quên mật khẩu
            </div>
          </div>
          <div className="self-stretch select-none flex-col justify-start items-start gap-4 flex">
            <LoadingButton
              loading={loading}
              disabled={loading}
              onClick={handleSubmit(onSubmit)}
              className="self-stretch h-[46px] bg-[#ff418c] cursor-pointer select-none rounded-lg flex-col justify-center items-center gap-2 flex"
            >
              Đăng nhập
            </LoadingButton>
            <div className="self-stretch justify-start items-center gap-3 inline-flex">
              <div className="text-center text-[#232323] text-xs font-normal leading-[18px]">
                Hoặc đăng nhập bằng
              </div>
            </div>
            <div className="self-stretch justify-start items-start gap-2 inline-flex">
              <div
                onClick={loginGoogle}
                className="cursor-pointer grow shrink basis-0 bg-[#ffecf4] rounded-lg flex-col justify-center items-center gap-2 inline-flex"
              >
                <div className="self-stretch pl-4 pr-6 py-3 justify-center items-center gap-2 inline-flex">
                  <div className="w-[18px] h-[18px] relative">
                    <img src={iconGoogle} alt="" />
                  </div>
                </div>
              </div>
              <div
                onClick={loginFacebook}
                className="cursor-pointer grow shrink basis-0 bg-[#ffecf4] rounded-lg flex-col justify-center items-center gap-2 inline-flex"
              >
                <div className="self-stretch pl-4 pr-6 py-3 justify-center items-center gap-2 inline-flex">
                  <div className="w-[18px] h-[18px] relative">
                    <img src={iconFacebook} alt="" />
                  </div>
                </div>
              </div>
              <div
                onClick={loginApple}
                className="cursor-pointer grow shrink basis-0 bg-[#ffecf4] rounded-lg flex-col justify-center items-center gap-2 inline-flex"
              >
                <div className="self-stretch pl-4 pr-6 py-3 justify-center items-center gap-2 inline-flex">
                  <div className="w-[18px] h-[18px] relative">
                    <img src={iconApple} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch justify-center items-start gap-1 inline-flex">
          <div className="text-[#232323] text-sm font-normal leading-snug">
            Bạn chưa có tài khoản?
          </div>
          <div
            onClick={() => {
              setIsRegisterOpen(true);
              handleClose();
            }}
            className="text-[#ff418c] text-sm font-semibold leading-snug cursor-pointer"
          >
            Đăng ký ngay
          </div>
        </div>
      </div>
    </ModalBase>
  );
}
