import { Menu } from "@mui/material";
import React, { Fragment } from "react";

export default function MenuBase({
  anchorEl,
  onClose,
  anchorOrigin = {
    vertical: "top",
    horizontal: "center",
  },
  transformOrigin = {
    vertical: "bottom",
    horizontal: "center",
  },
  children,
}) {
  return (
    <Fragment>
      {Boolean(anchorEl) && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={onClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          slotProps={{
            paper: {
              sx: {
                borderRadius: "12px",
                boxShadow: "0px 4px 8px 0px #00000029",
              },
            },
          }}
        >
          {children}
        </Menu>
      )}
    </Fragment>
  );
}
