import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import iconCancel24 from "assets/icons/cancel24.svg";
import iconPause from "assets/icons/pause-white50.svg";
import iconPlay from "assets/icons/play.svg";
import iconRepeat18 from "assets/icons/repeat18.svg";
import iconShuffle18 from "assets/icons/shuffle18.svg";
import iconBack18 from "assets/icons/skip-back18.svg";
import iconForward18 from "assets/icons/skip-forward18.svg";
import ButtonControl from "components/ButtonControl";
import IconSvg from "components/IconSvg";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import { formatTime } from "utils";
import {
  setTimeProgress,
  toggleLoop,
  togglePlayAudio,
} from "../../redux/slice/playerSlice";
import DiscThumbnail from "components/AudioPlayerPlayList/DiscThumbnail";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const convertLyrics = (lyrics) => {
  const regex = /\[\d{2}:\d{2}\.\d{2}\]/; // Regex pattern to split by [00:00.00]
  // Split the string
  const result = lyrics
    ?.split(regex)
    .filter((i) => !i.startsWith("[offset:0]"));
  return result;
};

export default function LyricDialog({
  open,
  handleClose,
  progressBarRef,
  audioRef,
  duration,
  isPlaying,
  error,
  loading,
  timeProgress,
  userInfo,
  songDetail,
  loop,
  shuffle,
  onShuffleAudio,
  onPreviousAudio,
  onNextAudio,
  isDisableNext,
  isDisablePrev,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const progressBarRef2 = React.useRef();

  const togglePlayPause = React.useCallback(() => {
    if (!error) {
      dispatch(togglePlayAudio());
    } else {
      enqueueSnackbar("Lỗi tải bài hát", {
        variant: "error",
      });
    }
  }, [dispatch, error, enqueueSnackbar]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (progressBarRef2.current) {
        progressBarRef2.current.value = audioRef.current.currentTime;
        progressBarRef2.current.style.setProperty(
          "--range-progress2",
          `${(audioRef.current.currentTime / duration) * 100}%`
        );
      }
      if (progressBarRef2.current && !progressBarRef2.current.max) {
        progressBarRef2.current.max = duration;
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [isPlaying, duration, audioRef]);

  const onResetProgressAudio = React.useCallback(() => {
    progressBarRef2.current.value = 0;
    progressBarRef2.current.style.setProperty("--range-progress2", `0%`);
  }, []);

  const handleProgressChange = React.useCallback(() => {
    console.log(progressBarRef2.current.value);
    audioRef.current.currentTime = Number(progressBarRef2.current.value);
    progressBarRef2.current.value = audioRef.current.currentTime;
    progressBarRef2.current.style.setProperty(
      "--range-progress2",
      `${(audioRef.current.currentTime / duration) * 100}%`
    );
    dispatch(setTimeProgress(Number(progressBarRef.current.value)));
  }, [audioRef, dispatch, duration, progressBarRef]);

  const handleLoop = () => {
    dispatch(toggleLoop());
    if (loop === 2) {
      audioRef.current.loop = true;
    } else {
      audioRef.current.loop = false;
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <div className="bg-[#591731] min-h-screen px-10 w-full overflow-hidden">
        <div className="h-[100px] px-10 justify-end pt-12 pb-3 items-center gap-6 flex">
          <div className="grow shrink basis-0 text-center text-white text-[32px] font-bold leading-10">
            Lời bài hát
          </div>
          <div
            onClick={handleClose}
            className="w-10 h-10 px-2.5 bg-[#eeeeee] rounded-3xl justify-center items-center flex cursor-pointer"
          >
            <div className="w-6 h-6 flex justify-center items-center">
              <img src={iconCancel24} alt="" />
            </div>
          </div>
        </div>
        <div className="container mx-auto h-[calc(100%-100px-114px)] overflow-hidden">
          <div className="h-full lg:pt-[60px] pb-4 flex-col justify-start items-start gap-8 flex">
            <div className="h-full self-stretch justify-center items-center gap-[60px] flex flex-col lg:flex-row">
              <div className="flex-col justify-start items-center gap-10 flex">
                <div className="scale-75 origin-center">
                  <DiscThumbnail thumb={songDetail?.thumb} isPlaying={true} />
                </div>

                <div className="h-[76px] flex-col justify-start items-center gap-4 inline-flex">
                  <div className="self-stretch h-[76px] flex-col justify-start items-center gap-1 flex">
                    <div className="self-stretch text-center text-white text-[32px] font-bold leading-10">
                      {songDetail?.title}
                    </div>
                    <div className="justify-start items-center gap-2 inline-flex">
                      <div className="text-[#cacaca] text-2xl font-normal leading-loose">
                        {songDetail?.artist?.name}
                      </div>
                      <div className="w-2 h-2 bg-[#cacaca] rounded-full" />
                      <div className="text-[#cacaca] text-2xl font-normal leading-loose">
                        {dayjs(songDetail?.create_time)?.format("YYYY")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <PerfectScrollbar className="h-full grow shrink basis-0 pr-2 self-stretch flex-col justify-start items-start gap-8 flex overflow-auto">
                <div className="">
                  {convertLyrics(songDetail?.lyrics)?.map((lyric, index) => {
                    return (
                      <div
                        key={`lyric-${index}`}
                        className="self-stretch text-[#8c8c8c] text-[24px] xl:text-[32px] font-bold leading-10"
                      >
                        {lyric}
                      </div>
                    );
                  })}
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 left-0 w-full h-[114px] pt-4">
          <div className="flex-col lg:flex-row flex justify-center items-center gap-4">
            <div className="flex justify-center items-center">
              <div className="flex items-center gap-[2px]">
                <ButtonControl
                  disabled={loading || !userInfo}
                  onClick={onShuffleAudio}
                >
                  <IconSvg
                    icon={iconShuffle18}
                    className="w-[18px] h-[18px] cursor-inherit"
                    color="#8C8C8C"
                  />
                  <span className="text-[10px]">{shuffle ? "on" : ""}</span>
                </ButtonControl>
                <ButtonControl
                  disabled={isDisablePrev}
                  onClick={() => {
                    onResetProgressAudio();
                    onPreviousAudio();
                  }}
                >
                  <IconSvg
                    icon={iconBack18}
                    className="w-[18px] h-[18px] cursor-inherit"
                    color="#FFFFFF"
                  />
                </ButtonControl>
                <button
                  disabled={loading}
                  onClick={togglePlayPause}
                  className="w-10 h-10 mx-3 outline-none"
                >
                  {loading ? (
                    <div className="w-10 h-10 flex justify-center items-center overflow-hidden">
                      <RotatingLines
                        strokeColor={"grey"}
                        strokeWidth={"5"}
                        animationDuration={"0.75"}
                        width={30}
                        visible={true}
                      />
                    </div>
                  ) : (
                    <img src={isPlaying ? iconPause : iconPlay} alt="" />
                  )}
                </button>
                <ButtonControl
                  disabled={isDisableNext}
                  onClick={() => {
                    onResetProgressAudio();
                    onNextAudio();
                  }}
                >
                  <IconSvg
                    icon={iconForward18}
                    className="w-[18px] h-[18px] cursor-inherit"
                    color="#FFFFFF"
                  />
                </ButtonControl>
                <ButtonControl
                  disabled={loading || !userInfo}
                  onClick={handleLoop}
                >
                  <IconSvg
                    icon={iconRepeat18}
                    className="w-[18px] h-[18px] cursor-inherit"
                    color="#8C8C8C"
                  />
                  <span className="text-[10px]">
                    {loop === 0
                      ? ""
                      : loop === 1
                      ? "on"
                      : loop === 2
                      ? "one"
                      : ""}
                  </span>
                </ButtonControl>
              </div>
            </div>

            <div className="flex items-center gap-[10px]">
              <span className="text-[12px] font-medium text-white">
                {formatTime(timeProgress)}
              </span>
              <div className="w-[333px] flex items-center">
                <input
                  className="input-karaoke"
                  disabled={!userInfo}
                  ref={progressBarRef2}
                  type="range"
                  defaultValue="0"
                  onChange={handleProgressChange}
                />
              </div>

              <span className="text-[12px] font-medium text-white">
                {formatTime(duration)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
