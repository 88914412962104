export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_INDEX = 0;

/* eslint-disable no-template-curly-in-string */
export const appRoutes = {
  home: "/",
  videos: "/videos",
  shorts: "/Shorts",
  videoDetail: (slug) => (slug ? `/video/${slug}` : "/video/:slug"),
  shortsDetail: (id) => (id ? `/shorts/${id}` : "/shorts/:id"),
  playerAlbum: (slug, id) =>
    id ? `/player/album/${slug}/${id}` : "/player/album/:slug/:id",
  playerPlaylist: (slug, id) =>
    id ? `/player/playlist/${slug}/${id}` : "/player/playlist/:slug/:id",
  playerSong: (slug, id) =>
    id ? `/player/song/${slug}/${id}` : "/player/song/:slug/:id",
  artist: (id) => (id ? `/artist/${id}` : "/artist/:id"),
  artistVideo: (id) => (id ? `/artist-video/${id}` : "/artist-video/:id"),
  artistShort: (id) => (id ? `/artist-short/${id}` : "/artist-short/:id"),
  artistAlbum: (id) => (id ? `/artist-album/${id}` : "/artist-album/:id"),
  artistTopSong: (id) =>
    id ? `/artist/${id}/top-song` : "/artist/:id/top-song",
  eventTicket: "/event-ticket",
  favorites: "/favorites",
  historyView: "/history-view",
  playlist: "/playlist",
  playlistAudioDetail: (slug, id) =>
    id ? `/playlist-audio/${slug}/${id}` : "/playlist-audio/:slug/:id",
  playlistVideoDetail: (id) =>
    id ? `/playlist-video/${id}` : "/playlist-video/:id",
  search: "/search",
};

export const ERROR_MESSAGE = {
  required: "Không được để trống",
  requiredCheck: "Phải chọn tối thiểu 1 lựa chọn",
  minLength: "Tối thiểu ${min} ký tự",
  maxLength: "Tối đa ${max} ký tự",
  length: "Phải có ${max} ký tự",
  max: "Tối đa ${max}",
  min: "Tối thiểu ${min}",
  inValid: "Không hợp lệ",
};

export const TYPE_COMMENT = {
  audio: 2,
  video: 1,
};
export const TYPE_SHARE_LINK = {
  video: 1,
  artist: 2,
  audio: 3,
  video_like: 4,
  video_short: 5,
};
export const TYPE_PLAY_IN_LIST = {
  song: "song",
  album: "album",
  recommend: "recommend",
};

export const FILTER_RANK = [
  {
    title: "Hôm nay",
    value: "1",
  },
  {
    title: "Theo tuần",
    value: "2",
  },
  {
    title: "Theo tháng",
    value: "3",
  },
  {
    title: "Theo quý",
    value: "4",
  },
  {
    title: "Theo năm",
    value: "5",
  },
];


export const TAB_KEY_FAVORITE = {
  AUDIO: "audio",
  VIDEO: "video",
  SHORT: "short",
}

export const TAB_FAVORITE = [
  { label: "Bài hát", value: TAB_KEY_FAVORITE.AUDIO },
  { label: "Video", value: TAB_KEY_FAVORITE.VIDEO },
  { label: "Short", value: TAB_KEY_FAVORITE.SHORT },
]

export const TAB_KEY_PLAYLIST = {
  AUDIO: "audio",
  VIDEO: "video",
};
export const TAB_PLAYLIST = [
  { label: "Bài hát", value: TAB_KEY_PLAYLIST.AUDIO },
  { label: "Video", value: TAB_KEY_PLAYLIST.VIDEO },
];


