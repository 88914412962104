import apiClient from "./apiClient";

export const loginByPassword = (body) => {
  return apiClient.post("/authentication/login-by-password", body);
};

export const logout = () => {
  return apiClient.post("/user/logout");
};

export const getOTP = (body) => {
  return apiClient.post("/authentication/check-get-otp", body);
};

export const signUpWithOTP = (body) => {
  return apiClient.post("/authentication/signup-with-otp", body);
};

export const getUserDetail = () => apiClient.post("/user/detail");

export const loginWithSocial = (body) =>
  apiClient.post("/authentication/login-with-social", body);

export const signUpToken = (dataPost) =>
  apiClient.post("/authentication/signup-with-id-token", dataPost);

export const forgetPasswordApi = (dataPost) =>
  apiClient.post("/authentication/forget-password", dataPost);

export const refreshLogin = (body) => {
  return apiClient.post("/authentication/refresh-login", body);
};

export const getTags = (body) => {
  return apiClient.post("/other/list-tags", body);
};

export const getAudioList = (body) => {
  return apiClient.post("/audios/list-web", body);
};

export const getVideoHomeList = (body) => {
  return apiClient.post("/videos/list-web", body);
};

export const getVideoList = (body) => {
  return apiClient.post("/videos/list-web", body);
};

export const getVideoDetail = (id) => {
  return apiClient.post("/videos/detail", { id });
};

export const getArtistList = (body) => {
  return apiClient.post("/artists/list-popular-web", body);
};

export const getArtistDetail = (id) => {
  return apiClient.post("/artists/detail", { id });
};

export const getAlbumList = (body) => {
  return apiClient.post("/albums/list-web", body);
};

export const getCategoryList = (body) => {
  return apiClient.post("/category/list", body);
};

export const getTopAudios = (body) => {
  return apiClient.post("/statistic/top-audios-web", body);
};

export const getTopVideos = (body) => {
  return apiClient.post("/statistic/top-videos-web", body);
};

export const getAudiosListend = (body) => {
  return apiClient.post("/audios/list-listend", body);
};

export const getAudiosDetail = (body) => {
  return apiClient.post("/audios/detail", body);
};

export const getOneAlbum = (body) => {
  return apiClient.post("/albums/get-one-web", body);
};

export const getPlaylistExclusive = (body) => {
  return apiClient.post("/albums/get-home-web", body);
};

export const getRelatedAudios = (body) => {
  return apiClient.post("/audios/related-audios-web", body);
};

export const addFavoriteAudio = (body) => {
  return apiClient.post("/audios/add-favorite", body);
};

export const getAlbumsDetail = (body) => {
  return apiClient.post("/albums/detail", body);
};

export const getFavorites = (body) => {
  return apiClient.post("/favorites/get-list", body);
};

export const getEventTicket = (body) => {
  return apiClient.post("/user-gifts/list-event-tickets", body);
};

export const getHomeBlock = (body) => {
  return apiClient.post("/other/get-home-blocks-web", body);
};

//playlists
export const getAudiosRecommend = (body) => {
  return apiClient.post("/audios/related-recommend", body);
};

export const getPlaylists = (body) => {
  return apiClient.post("/playlists/list", body);
};

export const createPlaylist = (body) => {
  return apiClient.post("/playlists/create", body);
};

export const addAudioToPlaylist = (body) => {
  return apiClient.post("/playlists/add-audio-to-playlist", body);
};

export const getHistoryViewVideo = (body) => {
  return apiClient.post("/videos/history", body);
};

export const getEventTickets = (body) => {
  return apiClient.post("/user-gifts/list-event-tickets", body);
};

//video
export const likeVideo = (body) => {
  return apiClient.post("/favorites/update", body);
};
//related-videos
export const getRelatedvideos = (body) => {
  return apiClient.post("/videos/related-videos", body);
};

//search
export const apiSearchWeb = (body) => {
  return apiClient.post("/home/search", body);
};

//share
export const shareLink = (body) => {
  return apiClient.post("/share/get-link", body);
};

export const getBlockByEndPoint = (endPoint, body) => {
  return apiClient.post(endPoint, body);
};
