import music from "assets/icons/menu/disc-music.svg";
import heart from "assets/icons/menu/heart.svg";
import playList from "assets/icons/menu/play-list.svg";
import shorts from "assets/icons/menu/shorts.svg";
import ticket from "assets/icons/menu/ticket.svg";
import video from "assets/icons/menu/video.svg";
import video2 from "assets/icons/menu/video2.svg";

export const MENU_LEFT = [
  {
    id: "music",
    title: "Nghe nhạc",
    icon: music,
    type: "menu",
    regex: /^\/$|^\/player(\/.*)$/,
    url: "/",
  },
  {
    id: "video",
    title: "Video",
    icon: video,
    type: "menu",
    regex: /^\/videos(\/.*)?$|^\/video(\/.*)?$/,
    url: "/videos",
  },
  {
    id: "shorts",
    title: "Shorts",
    icon: shorts,
    regex: /^\/shorts(\/.*)?$/,
    type: "menu",
    url: "/shorts",
  },
  {
    title: "Tài khoản",
    icon: "",
    type: "divider",
  },
  {
    id: "playList",
    title: "Playlist",
    icon: playList,
    url: "/playlist",
    regex: /^\/playlist(.*)?$/,
    type: "menu",
  },
  {
    id: "historyView",
    title: "Vừa xem",
    icon: video2,
    type: "menu",
    url: "/history-view",
    regex: /^\/history-view(\/.*)?$/,
  },
  {
    id: "favorites",
    title: "Yêu thích",
    icon: heart,
    type: "menu",
    url: "/favorites",
    regex: /^\/favorites(\/.*)?$/,
  },
  {
    id: "ticket",
    title: "Vé sự kiện",
    regex: /^\/event-ticket(\/.*)?$/,
    icon: ticket,
    type: "menu",
    url: "/event-ticket",
  },
  // { id: "gift", title: "Quà được tặng", icon: gift, type: "menu" },
  // { id: "loyalty", title: "Loyalty", icon: loyalty, type: "menu" },
];
