import { MenuItem } from "@mui/material";
import iconDots from "assets/icons/dots-three-outline-vertical.svg";
import iconLink16 from "assets/icons/link16.svg";
import iconShare16 from "assets/icons/share16.svg";
import MenuBase from "components/Menu/MenuBase/MenuBase";
import useActionPlayer from "hooks/useActionPlayer";
import useModalAuth from "hooks/useModalAuth";
import { useServices } from "hooks/useServices";
import { enqueueSnackbar } from "notistack";
import ModalAdd from "pages/PlayList/components/ModalAdd";
import { Fragment, useCallback, useMemo, useState } from "react";
import { addAudioPlayList } from "services/playlist";
import { checkResponse, copyToClipboard } from "utils";
import { getShareUrlAudio, getShareUrlVideo } from "utils/player";
import { TYPE_SHARE_LINK } from "../../constants";
import AddToPlayList from "./AddToPlayList";
import ModalShare from "./ModalShare";
import MenuItemBase from "components/Menu/MenuBase/MenuItemBase";

export default function MenuExtendSong({
  infoAudio,
  infoVideo,
  isVideo,
  isAudio,
  isShare = true,
  isCopyLink = true,
  isAddLibrary = true,
  onClick,
  onClose,
  anchorOrigin = {
    vertical: "top",
    horizontal: "center",
  },
  transformOrigin = {
    vertical: "bottom",
    horizontal: "center",
  },
  typeShare = TYPE_SHARE_LINK.audio,
  actionsFirst,
  actionsLast,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { checkAuthToST } = useModalAuth();
  const services = useServices();

  const {
    isOpenModalShare,
    onOpenModalShare,
    onCloseModalShare,
    isOpenModalAddPlaylist,
    onOpenModalAddPlaylist,
    onCloseModalAddPlaylist,
  } = useActionPlayer();

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
    onClick && onClick();
  }, [onClick]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    onClose && onClose();
  }, [onClose]);

  const shareUrl = useMemo(() => {
    if (isAudio) {
      return getShareUrlAudio({ title: infoAudio?.title, id: infoAudio?.id });
    }
    if (isVideo) {
      return getShareUrlVideo({ title: infoVideo?.title, id: infoVideo?.id });
    }
    return "";
  }, [isAudio, isVideo, infoAudio, infoVideo]);

  const handleCopyLink = useCallback(() => {
    checkAuthToST()
      .then(() => {
        copyToClipboard(shareUrl);
        handleClose();
      })
      .catch(() => {});
  }, [checkAuthToST, handleClose, shareUrl]);

  const onCreatePlaylist = () => {
    onOpenModalAddPlaylist();
  };

  const onCreateSuccess = (data) => {
    console.log(data.data);
  };

  const onAddToFavorite = (itemSelected) => {
    console.log(itemSelected);
    services(() =>
      addAudioPlayList({
        audio_id: infoAudio?.id?.toString(),
        playlist_id: itemSelected?.id,
      })
    ).then((res) => {
      if (checkResponse(res)) {
        enqueueSnackbar("Đã thêm vào playlist", {
          variant: "success",
        });
        handleClose();
      }
    });
  };

  const handleShare = useCallback(() => {
    checkAuthToST()
      .then(() => {
        onOpenModalShare();
        handleClose();
      })
      .catch(() => {});
  }, [checkAuthToST, handleClose, onOpenModalShare]);

  return (
    <Fragment>
      <div
        className="w-10 h-10 px-2.5 justify-center items-center flex cursor-pointer hover:bg-[#eeeeee] hover:rounded-[99px]"
        onClick={handleClick}
      >
        <div className="w-[18px] h-[18px] relative">
          <img src={iconDots} alt="" />
        </div>
      </div>
      <MenuBase
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {actionsFirst}
        {isAddLibrary && (
          <MenuItem sx={{ p: 0 }}>
            <AddToPlayList
              onAddToFavorite={onAddToFavorite}
              onCreatePlaylist={onCreatePlaylist}
              onCloseParent={handleClose}
            />
          </MenuItem>
        )}
        {isShare && (
          <MenuItemBase
            icon={iconShare16}
            onClick={handleShare}
            title={"Chia sẻ"}
          />
        )}
        {isCopyLink && (
          <MenuItemBase
            icon={iconLink16}
            onClick={handleCopyLink}
            title={"Sao chép link"}
          />
        )}
        {actionsLast}
      </MenuBase>
      {isOpenModalAddPlaylist && (
        <ModalAdd
          open={isOpenModalAddPlaylist}
          handleClose={onCloseModalAddPlaylist}
          loadData={onCreateSuccess}
        />
      )}
      {isOpenModalShare && (
        <ModalShare
          shareUrl={shareUrl}
          open={isOpenModalShare}
          handleClose={onCloseModalShare}
          typeShare={typeShare}
        />
      )}
    </Fragment>
  );
}
