import { Popover } from "@mui/material";
import iconplaylist20 from "assets/icons/add-playlist-20.svg";
import iconCaretRight from "assets/icons/caret-right12.svg";
import iconmusic20 from "assets/icons/music20.svg";
import iconPlaylist from "assets/icons/playlist-plus16.svg";
import MenuItemBase from "components/Menu/MenuBase/MenuItemBase";
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from "constants";
import useModalAuth from "hooks/useModalAuth";
import { useServices } from "hooks/useServices";
import { useCallback, useEffect, useMemo, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { getPlaylists } from "services";
import { useDebounce } from "use-debounce";
import { checkResponse } from "utils";

const AddToPlayList = ({
  onCreatePlaylist,
  onAddToFavorite,
  onCloseParent,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [valueDebounce] = useDebounce(inputValue, 300);

  const services = useServices();
  const [playlist, setPlaylist] = useState([]);
  const { userInfo } = useSelector((state) => state.auth);
  const { checkAuthToST } = useModalAuth();

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const id = useMemo(() => (open ? "simple-popover" : undefined), [open]);

  const loadData = useCallback(
    (text_search) => {
      services(() =>
        getPlaylists({
          page_id: DEFAULT_PAGE_INDEX,
          limit: DEFAULT_PAGE_SIZE,
          text_search: text_search ?? undefined,
        })
      ).then((res) => {
        if (checkResponse(res)) {
          setPlaylist(res.data.data || []);
        }
      });
    },
    [services]
  );

  useEffect(() => {
    if (userInfo) {
      loadData(valueDebounce);
    }
  }, [loadData, anchorEl, userInfo, valueDebounce]);

  const handleClick = useCallback(
    (event) => {
      const currentTarget = event.currentTarget;
      checkAuthToST()
        .then(() => {
          setAnchorEl(currentTarget);
        })
        .catch(() => {});
    },
    [checkAuthToST]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    onCloseParent && onCloseParent();
  }, []);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div>
      <MenuItemBase
        icon={iconPlaylist}
        iconRight={iconCaretRight}
        onClick={handleClick}
        title={"Thêm vào playlist"}
      />
      <Popover
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "12px",
              boxShadow: "0px 4px 8px 0px #00000029",
            },
          },
        }}
      >
        <div>
          <div className="p-3">
            <input
              placeholder="Tìm playlist"
              type="text"
              className="flex-1 pl-3 h-[34px] p-3 rounded-[24px] w-full border-none outline-none bg-[#EEEEEE] placeholder:text-sm placeholder:text-545454 placeholder:text-opacity-40"
              value={inputValue}
              onChange={handleChange}
            />
          </div>

          <MenuItemBase
            icon={iconplaylist20}
            onClick={() => {
              onCreatePlaylist && onCreatePlaylist();
              handleClose();
            }}
            title={"Tạo mới playlist"}
          />
          <PerfectScrollbar className="max-h-[138px] overflow-y-auto">
            {playlist?.map((item) => (
              <MenuItemBase
                key={`playlist-item-${item.id}`}
                icon={item.thumb ?? iconmusic20}
                title={item.title}
                onClick={() => onAddToFavorite(item)}
              />
            ))}
          </PerfectScrollbar>
        </div>
      </Popover>
    </div>
  );
};
export default AddToPlayList;
