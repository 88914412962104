import { copyToClipboard, getSlug } from "utils";
import { appRoutes } from "../constants";

export const checkIsLiked = (favatiteObject) => {
  return !!(
    favatiteObject &&
    typeof favatiteObject === "object" &&
    favatiteObject.hasOwnProperty("status") &&
    favatiteObject.status === 1
  );
};

export const getLinkVideo = (title, id) => {
  return `${appRoutes.videoDetail(getSlug(title))}?v=${id}`;
};

export const getShareUrlAudio = ({ title, id }) => {
  return `${window.location.origin}${appRoutes.playerSong(getSlug(title), id)}`;
};

export const getShareUrlAlbum = ({ title, id }) => {
  return `${window.location.origin}${appRoutes.playerAlbum(
    getSlug(title),
    id
  )}`;
};

export const getShareUrlPlaylist = ({ title, id }) => {
  return `${window.location.origin}${appRoutes.playlistAudioDetail(
    getSlug(title),
    id
  )}`;
};

export const getShareUrlVideo = ({ title, id }) => {
  return `${window.location.origin}${appRoutes.videoDetail(
    getSlug(title)
  )}?v=${id}`;
};

export const getShareUrlVideoPlaylist = ({ title, id }) => {
  return `${window.location.origin}${appRoutes.videoDetail(
    getSlug(title)
  )}?v=${id}`;
};

export const copyLinkSong = ({ title, id }) => {
  copyToClipboard(getShareUrlAudio({ title, id }));
};

export const copyLinkVideo = ({ title, id }) => {
  copyToClipboard(getShareUrlVideo({ title, id }));
};
