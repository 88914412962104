import axios from "axios";
import { resetPlayer } from "../redux/slice/playerSlice";
import { refreshLogin } from "services";
import { UAParser } from "ua-parser-js";
import {
  getItemStorage,
  INFO_LOGIN,
  removeItemStorage,
  saveItemStorage,
  UUID,
} from "utils/storage";
import { v4 as uuidv4 } from "uuid";
import { resetAuth, setStatusModalAuth } from "../redux/slice/authSlice";
import store from "../redux/store";

const parser = new UAParser();
let isRefreshing = false;
let failedQueue = [];
let uuid = getItemStorage(UUID);
if (!uuid) {
  uuid = uuidv4();
  saveItemStorage(UUID, uuid);
}

const processQueue = (error, data = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(data);
    }
  });

  failedQueue = [];
};

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  // baseURL: "https://api.starclub.vn/api",
  headers: {
    "Content-Type": "application/json",
  },
});
// Add a request interceptor

const currentLang = localStorage.getItem("LANG") || "vi";
apiClient.interceptors.request.use(
  (config) => {
    let customConfig = { ...config };
    const infoLogin = getItemStorage(INFO_LOGIN);

    customConfig.headers = {
      ...customConfig.headers,
      "Accept-Language": currentLang,
      apikey: process.env.REACT_APP_API_KEY,
      osinfo: parser.getOS()?.name,
      deviceinfo: parser.getBrowser()?.name,
      appversion: "1.1.0",
      uuid: uuid,
      fcmtoken: process.env.REACT_APP_FMC_TOKEN,
      apisecret: infoLogin?.apiSecret,
      userid: infoLogin?.userId,
    };
    return customConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      const infoLogin = getItemStorage(INFO_LOGIN);
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((data) => {
            const { apiSecret, userId } = data;
            originalRequest.headers["apisecret"] = apiSecret;
            originalRequest.headers["userid"] = userId;
            return apiClient(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        if (infoLogin) {
          refreshLogin({
            refresh_key: infoLogin?.refreshKey,
            user_id: infoLogin?.userId,
            api_secret: infoLogin?.apiSecret,
          })
            .then((response) => {
              const { apiSecret, userId, refreshKey } = response.data.data;
              saveItemStorage(INFO_LOGIN, {
                apiSecret: apiSecret,
                refreshKey: refreshKey,
                userId: userId,
              });
              apiClient.defaults.headers["apisecret"] = apiSecret;
              apiClient.defaults.headers["userid"] = userId;

              originalRequest.headers["apisecret"] = apiSecret;
              originalRequest.headers["userid"] = userId;

              processQueue(null, response.data);
              resolve(apiClient(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              console.error("Token refresh failed", err);
              reject(err);
            })
            .then(() => {
              isRefreshing = false;
            });
        }
      });
    }
    if (error.response?.status === 498) {
      removeItemStorage(INFO_LOGIN);
      store.dispatch(setStatusModalAuth(true));
      store.dispatch(resetAuth());
      store.dispatch(resetPlayer());
    }
    return Promise.reject(error);
  }
);
export default apiClient;
