import { MenuItem } from "@mui/material";
import React from "react";

export default function MenuItemBase({ onClick, icon, iconRight, title }) {
  return (
    <MenuItem onClick={onClick} sx={{ p: 0 }}>
      <div className="h-[46px] p-3 justify-start items-center gap-2.5 inline-flex">
        <div className="w-4 h-4 relative">
          <img src={icon} alt="" />
        </div>

        <div className="grow shrink basis-0 text-[#545454] text-sm font-normal leading-snug">
          {title}
        </div>
        <div className="w-3 h-3 relative">
          <img src={iconRight} alt="" />
        </div>
      </div>
    </MenuItem>
  );
}
