import clsx from "clsx";
import PlayerControlContainer from "components/AudioPlayerBottom";
import CommentDrawer from "components/CommentDrawer/CommentDrawer";
import MenuLeft from "components/Menu/MenuLeft/MenuLeft";
import MenuHeader from "components/MenuHeader";
import ModalNotiDowloadApp from "components/Modal/ModalNotiDowloadApp";
import ModalSignIn from "components/Modal/ModalSignIn";
import PlayList from "components/PlayList";
import { useServices } from "hooks/useServices";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItemStorage, INFO_LOGIN } from "utils/storage";
import { fetchUserDetail, setStatusModalAuth } from "./redux/slice/authSlice";
import { closeModalDownloadApp } from "./redux/slice/commonSlice";
import { fetchAudiosRecommend } from "./redux/slice/playerSlice";

export default function App({ children }) {
  const { open } = useSelector((state) => state.player);
  const { userInfo, openModal } = useSelector((state) => state.auth);
  const { openModalDownloadApp } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const services = useServices();

  useEffect(() => {
    const infoLogin = getItemStorage(INFO_LOGIN);
    if (!userInfo && infoLogin?.apiSecret && infoLogin?.userId) {
      dispatch(
        fetchUserDetail({
          id: infoLogin?.userId,
        })
      );
    }
    return () => {};
  }, [userInfo, dispatch, services]);

  useEffect(() => {
    if (userInfo) {
      dispatch(
        fetchAudiosRecommend({
          page_id: 0,
          limit: 10,
        })
      );
    }
  }, [dispatch, userInfo]);

  return (
    <div className="h-screen overflow-hidden relative">
      <div
        className={clsx("flex overflow-hidden transition-all duration-200", {
          "h-[calc(100%-90px)]": open,
          "h-screen": !open,
        })}
      >
        <MenuLeft />
        <div className="grow flex flex-col justify-start overflow-hidden">
          <MenuHeader />
          {children}
        </div>
        <PlayList />
        <CommentDrawer />
      </div>
      <PlayerControlContainer isOpenPlayer={open} />
      <ModalSignIn
        open={openModal}
        handleClose={() => {
          dispatch(setStatusModalAuth(false));
        }}
      />
      <ModalNotiDowloadApp
        open={openModalDownloadApp}
        handleClose={() => dispatch(closeModalDownloadApp())}
      />
    </div>
  );
}
